<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
// Get Browser with Version
navigator.browser=(()=>{const{userAgent:e}=navigator;let r,i=e.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i)||[];if(/trident/i.test(i[1]))return`IE ${(r=/\brv[ :]+(\d+)/g.exec(e)||[])[1]||""}`;if("Chrome"===i[1]){if(null!==(r=e.match(/\b(OPR|Edge)\/(\d+)/)))return r.slice(1).join(" ").replace("OPR","Opera");if(null!==(r=e.match(/\b(Edg)\/(\d+)/)))return r.slice(1).join(" ").replace("Edg","Edge (Chromium)")}return i=i[2]?[i[1],i[2]]:[navigator.appName,navigator.appVersion,"-?"],null!==(r=e.match(/version\/(\d+)/i))&&i.splice(1,1,r[1]),i.join(" ")})();

export default {
    name: 'App'
}
</script>

<style lang="scss">
    @import '@/assets/styles/style.scss';
</style>
