<template>
    <div>
        <header :class="[!darkTheme && 'light', 'header']">
            <a href="https://blanko.ca" target="_blank" rel="noopener noreferrer">
                <svg role="img" class="icon-logo"><use xlink:href="logo.svg#ico-logo"></use></svg>
            </a>
            <div class="tools">
                <div class="theme" @click="toggleTheme">
                    <svg v-if="darkTheme" role="img" class="icon-dark"><use xlink:href="symbols.svg#ico-dark"></use></svg>
                    <svg v-else role="img" class="icon-light"><use xlink:href="symbols.svg#ico-light"></use></svg>
                </div>
            </div>
        </header>
        <div :class="[!darkTheme && 'light', 'home']">
            <div class="content">
                <div class="titleContainer">
                    <h2 class="title">Mon adresse IP publique est</h2>
                </div>
                <div class="ipContainer" @click="copyIpAddress" @mouseover="resetCopied">
                    <h1 class="ipAddress">{{ip}}</h1>
                    <div class="copy">
                        <svg role="img" class="icon-copy"><use xlink:href="symbols.svg#ico-copy"></use></svg>
                        <p class="label">{{ copiedText ? 'Copié' : 'Copier dans le presse-papier'}}</p>
                    </div>
                </div>
                <div class="mailto">
                    <a class="btn" :href="mailtoContent">Envoyer par courriel</a>
                </div>
                <div class="extra">
                    <div :class="[showExtra && 'active', 'extraTitleContainer']" @click="toggleShowExtra">
                        <p :class="[showExtra && 'active', 'extraTitle link']">Voir plus d'informations</p>
                        <svg role="img" :class="[showExtra && 'isVisible', 'icon-chevron']"><use xlink:href="symbols.svg#ico-chevron"></use></svg>
                    </div>
                    <div v-show="showExtra" :class="[showExtra && 'isVisible', 'dataContainer']">
                        <p class="sectionTitle">Date & heure</p>
                        <div class="data">
                            <p>{{timeOpened}}</p>
                        </div>
                        <p class="sectionTitle">Système</p>
                        <div class="data" v-for="(value, name) in log" :key="'log ' + name">
                            <p><span>{{name}}: </span>{{value}}</p>
                        </div>
                        <p class="sectionTitle">Écran</p>
                        <div class="data" v-for="(value, name) in logScreen" :key="'logScreen ' + name">
                            <p><span>{{name}}: </span>{{value}}</p>
                        </div>
                        <p class="sectionTitle">Document</p>
                        <div class="data" v-for="(value, name) in logDocument" :key="'logDocument ' + name">
                            <p><span>{{name}}: </span>{{value}}</p>
                        </div>
                        <p class="sectionTitle">Fenêtre</p>
                        <div class="data" v-for="(value, name) in logWindow" :key="'logWindow ' + name">
                            <p><span>{{name}}: </span>{{value}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Home',
    data() {
        return {
            darkTheme: true,
            ip: null,
            copiedText: false,
            showExtra: false,
            mailtoContent: '',
            timeOpened: new Date(),
            log: {
                appVersion: navigator.appVersion,
                browser: navigator.browser,
                cookieEnabled: navigator.cookieEnabled,
                deviceMemory: navigator.deviceMemory,
                doNotTrack: navigator.doNotTrack,
                language: navigator.language,
                languages: navigator.languages,
                maxTouchPoints: navigator.maxTouchPoints,
                platform: navigator.platform,
                userAgent: navigator.userAgent,
                vendor: navigator.vendor,
                vendorSub: navigator.vendorSub,
                webdriver: navigator.webdriver,
            },
            logScreen: {
                width: screen.width,
                height: screen.height,
                availWidth: screen.availWidth,
                availHeight: screen.availHeight,
                colorDepth: screen.colorDepth,
                pixelDepth: screen.pixelDepth,
            },
            logDocument: {
                clientWidth: document.body.clientWidth,
                clientHeight: document.body.clientHeight,
            },
            logWindow: {
                innerWidth: window.innerWidth,
                innerHeight: window.innerHeight,
            },
        }
    },
    mounted() {
        this.getIpAddress();
        this.makeMailto();
    },
    methods: {
        makeMailto(ip) {
            const lb = '%0D%0A';
            const bigLB = lb + lb + lb + lb + lb + lb;
            const smallLB = lb + lb;
            const sectionLB = lb + lb + lb;

            const date = 'Page ouverte à : ' + this.timeOpened;
            const addressIp = 'Mon adresse IP publique : ' + ip;
            const sys = 'Informations%20du%20système%20:' + smallLB + JSON.stringify(this.log, null, lb);
            const scr = 'Informations%20de%20l’écran%20:' + smallLB + JSON.stringify(this.logScreen, null, lb);
            const doc = 'Informations%20du%20document%20:' + smallLB + JSON.stringify(this.logDocument, null, lb);
            const win = 'Informations%20de%20la%20fenêtre%20:' + smallLB + JSON.stringify(this.logWindow, null, lb);

            const to = 'info@blanko.ca';
            const subject = 'Informations%20sur%20mon%20adresse%20IP%20|%20ip.blanko.ca';
            const body = 'Si%20vous%20devez%20nous%20laissez%20plus%20d’informations,%20veuillez%20le%20faire%20ici.' + bigLB + '----------------------------------------------------------------------------------------------------' + smallLB + 'Début%20des%20informations%20générées%20automatiquement.' + sectionLB + date + sectionLB + addressIp + sectionLB + sys + sectionLB + scr + sectionLB + doc + sectionLB + win

            this.mailtoContent = 'mailto:' + to + '?subject=' + subject + '&body=' + body;
        },
        getIpAddress() {
            axios.get('https://api64.ipify.org?format=json').then(response => {
                const { ip } = response.data;
                this.ip = ip;
                this.makeMailto(ip);
            }).catch((error) => {
                console.log('Error getting IP', error); // eslint-disable-line no-console
            })
        },
        copyIpAddress() {
            navigator.clipboard.writeText(this.ip).then(() => {
                this.copiedText = true;
                setTimeout(() => {
                    this.copiedText = false;
                }, 3000);
            }, () => {
                // Try fallback clipboard copy
                this.copiedText = true;
                setTimeout(() => {
                    this.copiedText = false;
                }, 3000);
                let temp = document.createElement('textarea');
                temp.value = this.x;
                document.body.appendChild(temp);
                temp.select();
                document.execCommand('copy');
                document.body.removeChild(temp);
            });
        },
        resetCopied() {
            if (this.copiedText) {
                this.copiedText = false;
            }
        },
        toggleShowExtra() {
            this.showExtra = !this.showExtra
        },
        toggleTheme() {
            this.darkTheme = !this.darkTheme
        },
    }
}
</script>
